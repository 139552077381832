import request from '@/utils/request'

// 登录日志
export const login_daily = (params) => {
    return request.post('admin/loginLog')
}

// 下载日志
export const downaln_daily = (params) => {
    return request.post('admin/downLog')
}

