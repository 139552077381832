<!-- 登录日志 -->
<template>
  <Container>
    <Table :head-config="listConfig.headConfig" :load-data="listConfig.dataList">
      <template v-slot:handle="scope">
        <el-image style="width: 60px; height: 60px" :src="scope.data.row.u_avatar" />
      </template>
    </Table>
  </Container>
</template>

<script>
import { login_daily } from '@/api/daily'
export default {
    data() {
        return {
            listConfig: {
                headConfig: [ // 表头数据
                    { label: '头像', columnType: 'slot', slotName: 'handle' },
                    { label: '姓名', field: 'u_realname' },
                    { label: '职位', field: 'u_titles' },
                    { label: '手机号', field: 'u_mobile' },
                    { label: '登录时间', field: 'login_time' }

                ],
                dataList: [], // table数据
                pageData: { total: 0, pageIndex: 1, pageSize: 10 } // 分页数据
            },
            fileTypeId: ''
        }
    },
    computed: { },
    watch: {},
    created() {
        this.getLogin_daily()
    },
    mounted() { },
    methods: {
        async getLogin_daily() {
            const res = await login_daily()
            if (res.code === 0) {
                this.listConfig.dataList = res.data
            }
        }
    }
}
</script>

